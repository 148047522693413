import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>PAGINA NON TROVATA</h1>
    <p>Siamo spiacenti, ma hai richiesto una pagina che non esiste.</p>
  </Layout>
)

export default NotFoundPage
